const colors = {
  transparent: 'transparent',
  black: '#0a0b0a',
  white: '#fafafa',
  primary: '#81d600',
  secondary: '#88f2ce',
  green: {
    100: '#eeffd5',
    200: '#ddffaa',
    300: '#cdff80',
    400: '#bcff56',
    500: '#abff2c',
    600: '#9aff01',
    700: '#6eb700',
    800: '#5b9700',
    900: '#487700',
    1000: '#355800',
  },
  mint: {
    100: '#e1fcf3',
    200: '#c4f9e6',
    300: '#a6f5da',
    400: '#65eebf',
    500: '#41eab1',
    600: '#1ee6a2',
    700: '#16c88b',
    800: '#12a473',
    900: '#0e8158',
    1000: '#0a5e41',
  },
  neutral: {
    100: '#e3e3e3',
    200: '#cccbcb',
    300: '#b5b3b3',
    400: '#9f9c9c',
    500: '#898483',
    600: '#726c6c',
    700: '#5a5555',
    800: '#433f3e',
    900: '#2b2928',
    1000: '#151413',
  },
  success: {
    100: '#ccffbe',
    200: '#86ff7d',
    300: '#49ff3c',
    400: '#0dc200',
    500: '#098a00',
  },
  warning: {
    100: '#fef9b8',
    200: '#fef271',
    300: '#fdec2a',
    400: '#ad9f02',
    500: '#7c7201',
  },
  error: {
    100: '#f9cccc',
    200: '#f39999',
    300: '#ec6666',
    400: '#c11818',
    500: '#891111',
  },
};

export default colors;
