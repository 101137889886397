import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import isEqual from 'lodash/isEqual';
import { TitleBoxProps } from './types';

const TitleBoxContainer = ({ title, icon, ...props }: TitleBoxProps) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return <Box {...props}>{props.children}</Box>;
};

export default React.memo(TitleBoxContainer, isEqual);
