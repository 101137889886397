import React from 'react';
import { LocalizationProvider, ModalProvider, ThemeProvider } from '@providers';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TitleBoxContainer } from './components/TitleBox';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5,
      retryDelay: 1000,
    },
  },
});

const App = () => {
  const { t } = useTranslation();
  return (
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <ThemeProvider>
          <ModalProvider>
            <LocalizationProvider>
              <TitleBoxContainer title={t('App')} icon="app">
                <div>App</div>
              </TitleBoxContainer>
            </LocalizationProvider>
          </ModalProvider>
        </ThemeProvider>
      </React.StrictMode>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
